<template>
  <div class="xiangqing index">
    <Header :bg="bg"></Header>
    <div
      class="hehe"
      style="
        width: 100%;
        height: 64px;
        background-color: rgba(247, 248, 250, 1);
      "
    ></div>
    <div class="top">
      <div class="nav">
        <div>你的位置 ：</div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="xiangqing-nav"
        >
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/chenggong' }"
            >成功案例</el-breadcrumb-item
          >
          <el-breadcrumb-item class="dangqian">项目详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="xiangmu">
        <div
          class="xiangmu-son active"
          v-for="(item, index) in nav"
          :key="index"
          @click="change(item)"
          v-show="item.type == data.type"
        >
          <div class="em">
            <div class="dian"></div>
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="left">
        <div class="title">{{ data.title }}</div>
        <div class="kehu">
          <div class="l">服务客户</div>
          <div class="r">{{ data.kehu }}</div>
        </div>
        <!-- <div class="hezuo">
          <div class="l">合作企业</div>
          <div class="r">山脉</div>
        </div> -->
        <!-- <div class="time">
          <div class="l">落地时间</div>
          <div class="r">2020年3月</div>
        </div> -->
        <div class="city">
          <div class="l">落地城市</div>
          <div class="r">{{ data.city }}</div>
        </div>

        <div class="jieshao">项目介绍</div>
        <div class="text">
          {{
            data.text.length > 190 ? data.text.slice(0, 187) + "..." : data.text
          }}
        </div>
        <div class="change">
          <div class="zuo">
            <div class="shang">
              <span class="qian">{{ star }}</span
              >/<span class="hou">{{ end }}</span>
            </div>
            <div class="xia">
              <el-progress
                :percentage="percentage"
                :color="customColor"
              ></el-progress>
            </div>
          </div>
          <div class="you">
            <div class="xiangqian" @click="jian">
              <span class="el-icon-arrow-left"></span>
            </div>
            <div class="xianghou" @click="add">
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div>
          <el-carousel
            class="xiangqing-lunbo"
            arrow="never"
            indicator-position="none"
            ref="carousel"
            @change="changeimg"
            :interval="3000"
          >
            <el-carousel-item v-for="(item, index) in data.imgs" :key="index">
              <div class="imgbox">
                <img id="imgH" :src="item.src" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="content" ref="content" @mouseover="yiru" @mouseout="yichu">
        <div
          class="son"
          v-for="(item, index) in more"
          :key="index"
          @click="goto(item)"
        >
          <img class="img" :src="item.src" alt="" />
          <div class="name">
            <div class="title">
              {{
                item.title.length > 16
                  ? item.title.slice(0, 14) + "..."
                  : item.title
              }}
            </div>
            <div class="icon">{{ item.biaoqian }}</div>
          </div>
        </div>
        <div class="chakan" @click="gotoanli">
          <i class="el-icon-right"></i>
          <div>查看更多案例</div>
        </div>
      </div>

      <div
        class="zuo"
        ref="zuo"
        @click="xiangzuo"
        v-show="zuo"
        @mousemove="zuo = true"
        @mouseout="zuo = false"
      >
        <i class="el-icon-arrow-left"></i>
      </div>
      <div
        class="you"
        ref="you"
        @click="xiangyou"
        v-show="you"
        @mousemove="you = true"
        @mouseout="you = false"
      >
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <Footer></Footer>
    <div class="toback" ref="toback">
      <div class="toback-lianxi">
        <div class="toback-ph">
          <div class="toback-sons">
            <div class="toback-barss">
              <div class="toback-top">咨询电话</div>
              <div class="toback-bottom">133-3098-1239</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">技术支持</div>
              <div class="toback-bottom">189-8005-3442</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">售后电话</div>
              <div class="toback-bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="toback-wx">
          <div class="toback-son">
            <img src="@/assets/imgs/home/gongzonghao.png" alt />
            <div class="toback-tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
      <div class="toback-up" @click="up"></div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      type: 0,
      customColor: "#409eff",
      percentage: 0,
      nav: [
        { name: "智慧水务项目", type: 0 },
        { name: "城市安全项目", type: 1 },
        { name: "智慧城市项目", type: 2 },
        { name: "智慧政务项目", type: 3 },
      ],
      star: 1,
      end: 5,

      danwei: 0,
      index: 0,

      list: [
        { src: require("../../assets/imgs/anli/lunbo.png") },
        { src: require("../../assets/imgs/anli/lunbo.png") },
        { src: require("../../assets/imgs/anli/lunbo.png") },
        { src: require("../../assets/imgs/anli/lunbo.png") },
        { src: require("../../assets/imgs/anli/xiangmu (1).png") },
      ],

      chushi: 0,
      jieshu: 0,

      more: [
        {
          src: require("../../assets/imgs/anli/liushui (2).png"),
          title: "智慧水务六水数据信息平台",
          text: "以成都市城市大脑为基础，通过若干控制性重点水务信息化项目的建设整合和完善现有信息系统功能，全面接入以“六水”为代表的各业务功能打造全域感知的泛在感知系统，全量汇聚水务数据，强化数据挖掘在山洪预测、管网优化、水资源调度等领域的应用，实现全域感知（水质、水量等）、动态监测（各种水务设备和水利设施）、高效应用（多屏展示、GIS呈现等）的智慧水务平台。把具体业务系统分为水资源支撑模块、水安全保障模块、水净化提升模块、水生态修复模块、水管理创新模块及水文化传承模块，从而打造具有成都特色的业务系统。综合单位实际情况及工作的特殊性专门设计，使系统不仅在当前处于领先地位，而且能为今后的发展提供数据支撑。在功能设计、软件操作以及其它方面设身处地为用户着想，以用户为中心，设计以实用性为第一目的的系统。 采用面向用户的设计风格，设计满足人性化操作方式的展示系统。",
          imgs: [
            { src: require("../../assets/imgs/anli/liushui (2).png") },
            { src: require("../../assets/imgs/anli/liushui (1).png") },
            { src: require("../../assets/imgs/anli/liushui (3).png") },
            { src: require("../../assets/imgs/anli/liushui (4).png") },
            { src: require("../../assets/imgs/anli/liushui (5).png") },
            { src: require("../../assets/imgs/anli/liushui (6).png") },
            { src: require("../../assets/imgs/anli/liushui (7).png") },
            { src: require("../../assets/imgs/anli/liushui (8).png") },
            { src: require("../../assets/imgs/anli/liushui (9).png") },
            { src: require("../../assets/imgs/anli/liushui (10).png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/gongshui-1.png"),
          title: "供水数据接入与实时监测系统",
          text: "经开水务利用新一代的GIS技术，构建高效、合理、实用的供水管网信息系统，为水司各项工作开展及后续信息化系统建设提供数据支撑，进而应用至巡检、抢维修等业务中，辅助各部分工作与管网数据进行紧密结合，从而实现更加精细化的管理，实现各类业务流程、分析结果、运营信息基于地图的可视化展现，辅助水司进行宏观决策、综合运营，实现城市供水管网智慧运作，最终实现龙泉驿自来水公司“智慧水务”的发展目标。",
          imgs: [
            { src: require("../../assets/imgs/anli/gongshui-1.png") },
            { src: require("../../assets/imgs/anli/gongshui-2.png") },
            { src: require("../../assets/imgs/anli/gongshui-3.png") },
            { src: require("../../assets/imgs/anli/gongshui-4.png") },
            { src: require("../../assets/imgs/anli/gongshui-5.png") },
          ],
          kehu: "经开水务",
          city: "四川省成都市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/paishui (1).png"),
          title: "排水数据接入与实时监测系统",
          text: "建设内容由综合信息展示系统、业务应用系统、排水GIS服务平台和排水系统数据库建设等构成验。",
          imgs: [
            { src: require("../../assets/imgs/anli/paishui (1).png") },
            { src: require("../../assets/imgs/anli/paishui (2).png") },
            { src: require("../../assets/imgs/anli/paishui (3).png") },
            { src: require("../../assets/imgs/anli/paishui (4).png") },
            { src: require("../../assets/imgs/anli/paishui (5).png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/bim (1).png"),
          title: "锦江绿道府河BIM信息系统",
          text: "以成都地形图为基础，通过3DBIM渲染技术数字还原整个26公里河道，让用户在线体验锦江绿道的真实魅力。同时也让管理者通过绿道的监测信息，实时监测河道点位状态，做好及时防范。同时，在系统上叠加相关河段的巡查信息，便于直观掌握26公里绿道日常运维情况。",
          imgs: [
            { src: require("../../assets/imgs/anli/bim (1).png") },
            { src: require("../../assets/imgs/anli/bim (2).png") },
            { src: require("../../assets/imgs/anli/bim (3).png") },
            { src: require("../../assets/imgs/anli/bim (4).png") },
            { src: require("../../assets/imgs/anli/bim (5).png") },
            { src: require("../../assets/imgs/anli/bim (6).png") },
            { src: require("../../assets/imgs/anli/bim (7).png") },
            { src: require("../../assets/imgs/anli/bim (8).png") },
            { src: require("../../assets/imgs/anli/bim (9).png") },
            { src: require("../../assets/imgs/anli/bim (10).png") },
            { src: require("../../assets/imgs/anli/bim (11).png") },
            { src: require("../../assets/imgs/anli/bim (12).png") },
            { src: require("../../assets/imgs/anli/bim (13).png") },
            { src: require("../../assets/imgs/anli/bim (14).png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          title:
            "基层防汛监测预警体系-智能预警平台建设项目系统软硬件及系统集成标段",
          text: "本项目建设内容由成都市中心城区暴雨内涝预报预警系统（城市自动监测站网、城市洪涝监测预警平台）、山洪灾害监测预警平台（山洪灾害监测预警软件系统、山洪监测预警设施、）、南河流域洪水预报预警与实时风险分析系统（河道水文监测预警设施、河道断面补测、重点河段及城镇无人机航测与三维场景模型、实时洪水预警预报与风险分析模型构建、南河示范区模型率定验证与数据更新、洪水预报预警与实时分析系统平台）等构成。",
          src: require("../../assets/imgs/anli/jicheng (1).png"),
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
          imgs: [
            { src: require("../../assets/imgs/anli/jicheng (1).png") },
            { src: require("../../assets/imgs/anli/jicheng (2).png") },
            { src: require("../../assets/imgs/anli/jicheng (3).png") },
            { src: require("../../assets/imgs/anli/jicheng (4).png") },
            { src: require("../../assets/imgs/anli/jicheng (5).png") },
            { src: require("../../assets/imgs/anli/jicheng (6).png") },
          ],
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/chengyun1.png"),
          title: "智慧蓉城水务城运分中心V1",
          text: "市水务局深入贯彻落实“智慧蓉城”建设工作部署，按照“统一规划、统一标准、统筹项目、分工负责、分块实施、分步推进”的“三统三分”建设思路，以“一个数字底座、一张网、一个平台、五大板块”为总体框架，围绕新质生产力发展和绿色低碳创新等要求统筹推进建设，推进智慧水务的跨越式发展,开启智慧化治水管水新模式。",
          imgs: [
            { src: require("../../assets/imgs/anli/chengyun1.png") },
            { src: require("../../assets/imgs/anli/chengyun2.png") },
            { src: require("../../assets/imgs/anli/chengyun3.png") },
            { src: require("../../assets/imgs/anli/chengyun4.png") },
            { src: require("../../assets/imgs/anli/chengyun5.png") },
            // { src: require("../../assets/imgs/anli/chengyun6.png") },
            // { src: require("../../assets/imgs/anli/chengyun7.png") },
            // { src: require("../../assets/imgs/anli/chengyun8.png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/chengyun11.png"),
          title: "智慧蓉城水务城运分中心V2",
          text: "市水务局深入贯彻落实“智慧蓉城”建设工作部署，按照“统一规划、统一标准、统筹项目、分工负责、分块实施、分步推进”的“三统三分”建设思路，以“一个数字底座、一张网、一个平台、五大板块”为总体框架，围绕新质生产力发展和绿色低碳创新等要求统筹推进建设，推进智慧水务的跨越式发展,开启智慧化治水管水新模式。",
          imgs: [
            { src: require("../../assets/imgs/anli/chengyun11.png") },
            { src: require("../../assets/imgs/anli/chengyun22.png") },
            { src: require("../../assets/imgs/anli/chengyun33.png") },
            { src: require("../../assets/imgs/anli/chengyun44.png") },
            { src: require("../../assets/imgs/anli/chengyun55.png") },
            { src: require("../../assets/imgs/anli/chengyun66.png") },
            { src: require("../../assets/imgs/anli/chengyun77.png") },
            { src: require("../../assets/imgs/anli/chengyun88.png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/gongshui2.png"),
          title: "供水数据接入和实时监测系统V2",
          text: "实现区县数据和实时监测数据接入，从水源地到水厂，总体供水到区域供水到小范围供水的全过程，全局面的水量水质的在线监控与预警预报。建设供水运行管理一张图。重点呈现供水水厂（站）各管理要素信息，如水厂（站）、供水区域、水源地、出厂水水质监测、水厂（站）运行状态、供水管网等，为供水主管部门实时了解水厂（站）供水情况和决策分析提供支撑。",
          imgs: [
            { src: require("../../assets/imgs/anli/gongshui2.png") },
            { src: require("../../assets/imgs/anli/gongshui1.png") },
            { src: require("../../assets/imgs/anli/gongshui3.png") },
            { src: require("../../assets/imgs/anli/gongshui4.png") },
            { src: require("../../assets/imgs/anli/gongshui5.png") },
          ],
          kehu: "成都市水务局、成都市供排水监管中心、成都各区(市)县水务局、成都市各供水厂",
          city: "四川省成都市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/paishui2.png"),
          title: "排水数据接入和实时监测系统V2",
          text: "通过数据接入和填报，动态管理业务流程，且实现大屏对系统信息进行呈现，实现对排水专题图、排水管网、排口、排水户、城镇污水处理厂、再生水利用、下穿隧道、污泥处理和农村生活污水等专题信息的管理、统计和分析。",
          imgs: [
            { src: require("../../assets/imgs/anli/paishui2.png") },
            { src: require("../../assets/imgs/anli/paishui1.png") },
            { src: require("../../assets/imgs/anli/paishui3.png") },
            { src: require("../../assets/imgs/anli/paishui4.png") },
            { src: require("../../assets/imgs/anli/paishui5.png") },
          ],
          kehu: "成都市水务局、成都市供排水监管中心、成都各区(市)县水务局、成都市各污水厂、兴蓉市政",
          city: "四川省成都市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/wenjiang1.png"),
          title: "智慧水务平台建设一期服务采购项目",
          text: "建设构建温江区智慧水务雏形，建设以智慧防汛、智慧河湖、智慧供水、智慧排水四大板块为基础的温江区智慧水务。积极响应国家政策，同时实现改变水务信息化水平严重滞后现状、改变传统管理服务模式，实现城市供排水业务、供排水设施的整体统筹归集、融合，提升集中监测，集中管理，集中控制，科学调度能力，提供科学化决策、精细化管理、智慧化应用技术手段，创新供排水业务的管理体制机制，全面提升温江区水务行业管理现代化水平。",
          imgs: [
            { src: require("../../assets/imgs/anli/wenjiang1.png") },
            { src: require("../../assets/imgs/anli/wenjiang2.png") },
            { src: require("../../assets/imgs/anli/wenjiang3.png") },
            { src: require("../../assets/imgs/anli/wenjiang4.png") },
            { src: require("../../assets/imgs/anli/wenjiang5.png") },
          ],
          kehu: "温江市水务局【供水科、排水科及防汛办】 ，温江区城投顺源生态环境建设有限公司环境公司",
          city: "四川省成都市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/chongzhou2.png"),
          title: "崇州智慧水务项目",
          text: "通过夯实基础、融同连接、挖掘数据、加强感知、促进应用，搭建智慧水务总体架构，建设“一大脑、一张图、N 应用、一体系”，强化防汛减灾、河湖管理等难点问题，建成全域感知、动态监测、实时预警、协同管理和高效应用的智慧水务平台，达到监测、预警、调度和辅助决策全过程，有效支撑水务业务管理科学化精细化。",
          imgs: [
            { src: require("../../assets/imgs/anli/chongzhou2.png") },
            { src: require("../../assets/imgs/anli/chongzhou1.png") },
            { src: require("../../assets/imgs/anli/chongzhou3.png") },
            { src: require("../../assets/imgs/anli/chongzhou4.png") },
            { src: require("../../assets/imgs/anli/chongzhou5.png") },
          ],
          kehu: "崇州市水务局",
          city: "四川省崇州市",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/qingyang2.png"),
          title: "防汛抗旱指挥中心升级改造服务项目",
          text: "本项目通过夯实基础、融同连接、挖掘数据、加强感知、促进应用，扩充完善青羊智慧水务总体架构，建成全域感知、动态监测、实时预警、协同管理和高效应用的智慧水务平台，构建“预报、预警、预演、预案”四预体系，达到监测、预警、调度和辅助决策全过程，有效支撑水务业务管理科学化精细化。",
          imgs: [
            { src: require("../../assets/imgs/anli/qingyang2.png") },
            { src: require("../../assets/imgs/anli/qingyang1.png") },
            { src: require("../../assets/imgs/anli/qingyang3.png") },
            { src: require("../../assets/imgs/anli/qingyang4.png") },
            { src: require("../../assets/imgs/anli/qingyang5.png") },
          ],
          kehu: "成都市青羊区综合行政执法局",
          city: "四川省成都市青羊区",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/longquan2.png"),
          title: "龙泉山城市森林公园多要素地质调查项目",
          text: "龙泉山城市森林公园地质信息系统建立在成都市城市地质信息系统基础上，实现森林公园地上地面地下数据一体化存储管理、查询统计、综合分析、二次开发利用及信息共享等功能。以地质调查成果信息服务、地质资料信息服务、地质调查信息化建设为核心，实现地面地下一体化管理，建设多专业、多参数立体地质数据输入、管理、可视化和分析评价为一体的城市森林公园地下空间决策分析平台。",
          imgs: [
            { src: require("../../assets/imgs/anli/longquan2.png") },
            { src: require("../../assets/imgs/anli/longquan1.png") },
            { src: require("../../assets/imgs/anli/longquan3.png") },
            { src: require("../../assets/imgs/anli/longquan4.png") },
            { src: require("../../assets/imgs/anli/longquan5.png") },
          ],
          kehu: "龙泉山森林公园管委会",
          city: "四川省成都市龙泉驿区",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          src: require("../../assets/imgs/anli/xinjin2.png"),
          title: "新津智慧水务",
          text: "基于成都市水务局已建设的智慧水务数据信息平台数据，提取新津区域内的智慧防汛、智慧河湖、智慧供水、智慧排水数据。紧密结合成都市智慧水务的建设，为全面完成年度工作目标，推进有关工作任务，需搭建新津区智慧水务调度展示系统，该系统将汇聚基础数据、规划数据、设施数据、监管数据、维护数据等相关信息，通过模块提炼通晓水务基础设施的多重指标、基础属性、空间分布和实时动态等数据，从而体现规范化管理，为新津区的水务业务情况、防汛管控预警等业务提供重要的数据展示与决策支撑。健全完善的新津水务特色四大板块，统筹自然灾害、预警、预报信息资源，综合运用现代信息手段，拓展完善水务信息化建设，提升水务信息化覆盖面，达到智慧水务信息化建设一体化。",
          imgs: [
            { src: require("../../assets/imgs/anli/xinjin2.png") },
            { src: require("../../assets/imgs/anli/xinjin1.png") },
            { src: require("../../assets/imgs/anli/xinjin3.png") },
            { src: require("../../assets/imgs/anli/xinjin4.png") },
            { src: require("../../assets/imgs/anli/xinjin5.png") },
          ],
          kehu: "新津水务局",
          city: "四川省成都市新津区",
          type: 0,
          biaoqian: "智慧水务",
        },
        {
          title:
            "四川地质环境监测总站三大片区典型泥石流监测预警示范点建设系统设备采购项目",
          text: "本工程建设内容由山洪灾害普查、危险区的划定、临界雨量和水位等预警指标的确定、县级预警平台中心、乡镇预警发布平台、前端监测站点和群测群防构成。",
          src: require("../../assets/imgs/anli/sanda.png"),
          kehu: "四川省国土厅",
          city: "四川省",
          type: 1,
          imgs: [
            { src: require("../../assets/imgs/anli/sanda.png") },
            { src: require("../../assets/imgs/anli/sanda-1.png") },
            { src: require("../../assets/imgs/anli/sanda-2.png") },
            { src: require("../../assets/imgs/anli/sanda-3.png") },
          ],
          biaoqian: "城市安全",
        },
        {
          title: "联网报警升级改造信息化工程项目施工",
          text: "本项目建设内容由指挥大厅音视频系统、决策大厅音视频系统、小会议室音视频系统、各功能房指挥席位、操作席位及办公家具、UPS机房及电源配电系统、音控室设备、数据中心机房系统、辅助配套设备、指挥中心功能性装修、指挥中心空调系统、指挥中心消防系统等组成。",
          src: require("../../assets/imgs/anli/lianwang.jpg"),
          kehu: "中融安保",
          city: "四川省成都市",
          type: 2,
          imgs: [
            { src: require("../../assets/imgs/anli/lianwang.jpg") },
            { src: require("../../assets/imgs/anli/lianwang-1.jpg") },
            { src: require("../../assets/imgs/anli/lianwang-2.jpg") },
          ],
          biaoqian: "智慧城市",
        },
        {
          title:
            "智能交通空间地理信息基础支撑及服务系统项目系统软硬件与系统集成",
          text: "本项目建设内容由标准规范建设、智能交通管控底图融合配图、道路渠化建设、智能交通管控模型建设、智能交通专题数据建设、应用系统建设、基础设施建设等构成；我公司负责标准规范建设、智能交通管控底图融合配图、智能交通管控模型建设、智能交通专题数据建设、应用系统建设、基础设施建设等工作，合同金额占2259.3127万元。",
          src: require("../../assets/imgs/anli/jiaotong.png"),
          kehu: "成都交投",
          city: "四川省成都市",
          type: 2,
          imgs: [{ src: require("../../assets/imgs/anli/jiaotong.png") }],
          biaoqian: "智慧城市",
        },
      ],
      timer: null,
      arr: [],
      bg: "black",

      zuo: false,
      you: false,
      judge: true,
      transX: 0,
      data: {},
      sessionStorageObj: {},
    };
  },
  methods: {
    change(item) {
      this.type = item.type;
    },
    add() {
      this.$refs.carousel.next();
    },
    jian() {
      this.$refs.carousel.prev();
    },
    changePro(item) {
      if (item.length >= 10) this.end = item.length;
      else this.end = "0" + item.length;
    },
    changeimg(now, old) {
      this.changePro(this.data.imgs);
      // console.log('下一张',now,old,this.star,this.end,this.danwei);
      //往左最大值 重置
      if (now == 0 && old == this.end - 1) {
        //console.log(" 最大重置 ");
        this.star = "0" + 1;
        this.percentage = (this.star / this.end) * 100;
      }
      //往右最小值重置
      if (now == this.end - 1 && old == 0) {
        //  console.log(" 最小重置 ");
        this.star = this.end;
        this.percentage = (this.star / this.end) * 100;
      }
      if (now > old) {
        //数字切换和补0
        if (this.star < this.end) {
          this.star++;
          if (this.star >= 1 && this.star < 10) {
            this.star = "0" + this.star;
            // console.log("this.star :>> ", this.star);
          }
        }
        //增加进度条数量
        if (this.percentage < 100) {
          this.percentage += this.danwei;
        }
      } else {
        if (this.star > 1) {
          this.star--;
          if (this.star >= 1 && this.star < 10) {
            this.star = "0" + this.star;
          }
        }
        if (this.percentage > this.danwei) {
          this.percentage -= this.danwei;
        }
      }
    },

    // anxia(v) {
    //   console.log("anxia :>> ", v);
    //   this.chushi = v.x;
    // },
    // yidong(v) {
    //   // console.log("yidong :>> ", v);
    //   v;
    // },
    // taiqi(v) {
    //   console.log("taiqi :>> ", v);
    //   this.jieshu = v.x;
    //   if (this.chushi - this.jieshu >= 0) {
    //     console.log("往左");
    //   } else {
    //     console.log("往右");
    //   }
    // },

    xiangzuo() {
      if (this.transX != 0) {
        this.transX += 100;
      } else {
        this.zuo = false;
      }
      this.$refs.content.style.transform = `translateX(${this.transX}vw)`;
    },
    xiangyou() {
      if (-this.transX * 19.2 <= 3840) {
        this.transX -= 100;
      } else {
        this.you = false;
      }
      this.$refs.content.style.transform = `translateX(${this.transX}vw)`;
    },

    yiru() {
      // if (this.judge) {
      this.you = true;
      // } else {
      this.zuo = true;
      // }
    },
    yichu() {
      this.zuo = false;
      this.you = false;
    },

    goto(i) {
      this.data = i;
      window.sessionStorage.setItem("anli", JSON.stringify(i));
      document.body.scrollTop = 150;
    },

    up() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let sudu = 40;
      if (top >= 0 && top < 1000) {
        sudu = 40;
      } else if (top >= 1000 && top < 2000) {
        sudu = 60;
      } else if (top >= 2000 && top < 3000) {
        sudu = 100;
      } else if (top >= 3000 && top < 4000) {
        sudu = 140;
      } else {
        sudu = 200;
      }
      this.timer = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            sudu;

        if (top <= 0) {
          clearInterval(this.timer);
        }
      }, 10);

      // document.body.scrollTop = 0;
    },
    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.arr.push(scroll);
      //console.log("scroll :>> ", scroll);
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");

          if (scroll >= 470) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          // console.log("往上滑");
        }
        if (scroll > 120) {
          this.$refs.toback.style.opacity = "1";
        } else {
          this.$refs.toback.style.opacity = "0";
        }
      }
    },
    gotoanli() {
      this.$router.push("/chenggong");
    },
  },
  created() {
    this.sessionStorageObj = JSON.parse(sessionStorage.getItem("anli")); //本地存储上一个历史记录

    this.data = Object.keys(this.$route.params).length
      ? this.$route.params
      : this.sessionStorageObj; //初始化接受的参数
    // console.log('data :>> ',this.data,'sessionStorageObj :>> ',this.sessionStorageObj);

    // if (this.data.imgs.length > 0) {
    //   this.list = this.data.imgs;
    // }
    // this.list = this.data.imgs;

    document.body.scrollTop = 0;
    this.end = this.data.imgs.length; //初始化轮播长度
    // console.log('endend',this.end);
    if (this.star >= 1 && this.star < 10) {
      this.star = "0" + this.star;
    }
    if (this.end >= 1 && this.end < 10) {
      this.end = "0" + this.end;
    }
    // this.end
    this.danwei = (this.star / this.end) * 100; //递增或递减量

    this.percentage = (this.star / this.end) * 100; //初始化最小单位
  },
  mounted() {
    document.body.scrollTop = 0;
    window.addEventListener("scroll", this.scroll, true);
    // this.$nextTick(() => {
    //   let imgHeight = document.querySelector("#imgH");
    //   console.log('imgHeight :>> ',imgHeight.style);
    // });
  },
  beforeDestroy() {
    // this.time=null
    window.removeEventListener("scroll", this.scroll, true);
  },
};
</script>

<style lang="scss" scoped>
.test {
  width: 100%;
  height: 200px;
  background-color: rgba(243, 243, 243, 1);
}
.xiangqing {
  width: 1920px;
  height: 100%;

  .top {
    width: 100%;
    height: 144px;
    background-color: rgba(247, 248, 250, 1);

    padding: 0 260px;
    padding-top: 40px;
    .nav {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      color: rgb(199, 199, 199);
    }
    .xiangmu {
      width: 100%;
      display: flex;
      margin-top: 40px;
      .xiangmu-son {
        width: 150px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;

        color: #b7b7b7;
        margin-right: 50px;
        position: relative;
        cursor: pointer;
        .em {
          width: 12px;
          height: 12px;
          background: rgba(22, 100, 255, 0.4);

          border-radius: 50%;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          // border: 2px solid rgba(22, 100, 255, 0.4);

          position: absolute;
          display: none;
          .dian {
            width: 8px;
            height: 8px;

            background: rgba(22, 100, 255, 1);
            border-radius: 50%;
          }
        }
        .name {
          margin-left: 20px;
        }
      }
      .active {
        color: #1664ff;
        .em {
          display: flex;
        }
      }
    }
  }
  .box {
    width: 100%;
    height: 680px;
    background-color: #fff;
    padding-left: 260px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 336px;
      height: 100%;
      position: relative;
      .l {
        font-size: 14px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        color: #888888;
      }
      .r {
        font-size: 14px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        color: #333333;
        margin-left: 25px;
      }
      .title {
        max-width: 276px;
        font-size: 28px;
        font-family: Alibaba PuHuiTi;
        font-weight: normal;
        line-height: 40px;
        color: #333333;
        margin-top: 60px;
      }
      .kehu {
        display: flex;
        margin-top: 40px;
        .l {
          width: 123px;
        }
        .r {
          width: 400px;
          text-align: left;
          margin-left: 0;
        }
      }
      .hezuo {
        display: flex;
        margin-top: 14px;
      }
      .time {
        display: flex;
        margin-top: 14px;
      }
      .city {
        display: flex;
        margin-top: 14px;
        .l {
          width: 123px;
        }
        .r {
          width: 400px;
          text-align: left;
          margin-left: 0;
        }
      }
      .jieshao {
        font-size: 16px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;

        color: #333333;
        margin-top: 40px;
      }
      .text {
        font-size: 14px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        line-height: 24px;
        color: #888888;
        margin-top: 30px;
      }
      .change {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 46px;
        .zuo {
          .shang {
            font-size: 14px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 20px;
            color: #b7b7b7;
            .qian {
              color: rgba(51, 51, 51, 1);
            }
          }
          .xia {
            width: 74px;
            height: 4px;
          }
        }
        .you {
          display: flex;
          .xiangqian {
            width: 32px;
            height: 32px;
            border: 1px solid #d9d9d9;
            opacity: 1;
            color: rgba(200, 200, 200, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .xiangqian:hover {
            background-color: rgba(22, 100, 255, 1);
          }
          .xianghou {
            width: 32px;
            height: 32px;
            // background-color: rgba(22, 100, 255, 1);
            border: 1px solid #d9d9d9;
            color: rgba(200, 200, 200, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .xianghou:hover {
            background-color: rgba(22, 100, 255, 1);
          }
        }
      }
    }
    .right {
      width: 1300px;
      height: 100%;
      div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .imgbox {
          width: 100%;
          height: auto;

          img {
            width: 100%;
          }
        }
      }
    }
  }
  .bottom {
    width: 1920px;
    height: 418px;
    background-color: rgba(243, 243, 243, 1);
    overflow-x: auto;
    display: flex;
    align-items: center;
    position: relative;
    .content {
      // width: 1000px;
      //overflow-x: scroll;
      //display: flex;
      //  width: 3200px;
      //width: max-content;
      display: flex;
      transition: all 1s linear;
      .son {
        width: 360px;
        height: 300px;
        margin-right: 30px;
        // display: inline-block;
        // display: inline;
        background-color: #fff;
        .img {
          width: 100%;
          height: 200px;
        }
        .name {
          width: 100%;
          height: 100px;
          padding: 16px 20px;
          position: relative;
          .title {
            font-size: 20px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;

            color: #333333;
          }
          .icon {
            // width: 70px;
            // max-width: 200px;
            position: absolute;
            left: 20px;
            bottom: 16px;
            //height: 28px;
            padding: 6px;
            background: rgba(231, 239, 255, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;

            color: #548dff;
          }
        }
      }
      .son:first-child {
        margin-left: 260px;
      }
      .chakan {
        width: 200px;
        height: 300px;
        background-color: #fff;
        cursor: pointer;
        color: #888888;
        font-size: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-family: Alibaba PuHuiTi Xi;
        i {
          font-size: 30px;
        }
      }
      .chakan:hover {
        color: #1664ff;
      }
    }
    .zuo {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: absolute;
      top: 124px;
      left: 260px;
      // background-color: #409eff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      transition: all 0.3s linear;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(20px);
      color: #fff;
      font-weight: 700;
    }
    .you {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: absolute;
      top: 124px;
      right: 260px;
      // background-color: #409eff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s linear;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(20px);
      color: #fff;
      font-weight: 700;
    }
  }
}

// ::-webkit-scrollbar {
//   width: 12px;
// }

// ::-webkit-scrollbar-thumb {
//   background: linear-gradient(rgb(17, 157, 212), rgb(17, 212, 105));
//   border-radius: 5px;
//   transition: 1s;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: linear-gradient(rgb(17, 212, 105), rgb(17, 157, 212));
// }

// ::-webkit-scrollbar {
//   display: none;
//   /* Chrome Safari */
// }

::-webkit-scrollbar {
  border: none;
}
</style>